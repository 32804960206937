.summary {
  text-align: center;
  font-size: larger;
}
.icons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
}

.icon-container.selected {
  background-color: #cda24d;
}
.icons-wrapper .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 120px;
  padding: 15px 5px 5px 5px;
}

.icons-wrapper .icon-container:hover {
  background-color: #cda24d;
}

.icons-wrapper .icon-container .caption {
  margin-top: 10px;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
}

.icons-wrapper .icon-container img {
  width: 100%;
}